import { createBrowserHistory } from 'history'
// import { send } from './tracking'

const history = createBrowserHistory()

//history.listen(location => send({ hitType: "pageview", page: location.pathname }))
//
// enhanced measurement options have been configured to capture page changes
// based on history events so this line may not be needed
//history.listen(location => send("pageview"))

export default history
